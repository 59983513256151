/* eslint-disable linebreak-style */
import * as posedetection from '@tensorflow-models/pose-detection';
import * as scatter from 'scatter-gl';

import * as params from './params';
import {bestFitLine} from './util';

// These anchor points allow the pose pointcloud to resize according to its
// position in the input.
const ANCHOR_POINTS = [[0, 0, 0], [0, 1, 0], [-1, 0, 0], [-1, -1, 0]];

const ADJACENT_KEYPOINTS = {
  'left_shoulder_to_left_elbow': [11, 13],
  'left_elbow_to_left_wrist': [13, 15],
  'right_shoulder_to_right_elbow': [12, 14],
  'right_elbow_to_right_wrist': [14, 16],
  'left_hip_to_left_knee': [23, 25],
  'left_knee_to_left_ankle': [25, 27],
  'right_hip_to_right_knee': [24, 26],
  'right_knee_to_right_ankle': [26, 28],
  'left_shoulder_to_left_hip': [11, 23],
  'right_shoulder_to_right_hip': [12, 24],
  'left_hip_to_right_hip': [23, 24],
  'left_shoulder_to_right_shoulder': [11, 12],
  'left_hip_to_right_hip': [23, 24],
};

const ANGLES_TO_KEYPOINTS = {
  'left_arm': [13, 11, 23], // left_hip left_shoulder left_elbow
  'right_arm': [24, 12, 14], // right_hip right_shoulder right_elbow
  'left_elbow': [15, 13, 11], // left_shoulder left_elbow left_wrist
  'right_elbow': [12, 14, 16], // right_shoulder right_elbow right_wrist
  'left_knee': [23, 25, 27],
  'right_knee': [24, 26, 28],
};

// #ffffff - White
// #800000 - Maroon
// #469990 - Malachite
// #e6194b - Crimson
// #42d4f4 - Picton Blue
// #fabed4 - Cupid
// #aaffc3 - Mint Green
// #9a6324 - Kumera
// #000075 - Navy Blue
// #f58231 - Jaffa
// #4363d8 - Royal Blue
// #ffd8b1 - Caramel
// #dcbeff - Mauve
// #808000 - Olive
// #ffe119 - Candlelight
// #911eb4 - Seance
// #bfef45 - Inchworm
// #f032e6 - Razzle Dazzle Rose
// #3cb44b - Chateau Green
// #a9a9a9 - Silver Chalice
const COLOR_PALETTE = [
  '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
  '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
  '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9'
];
export class RendererCanvas2d {
  constructor(canvas) {
    this.ctx = canvas.getContext('2d');
    this.scatterGLEl = document.querySelector('#scatter-gl-container');
    this.scatterGL = new scatter.ScatterGL(this.scatterGLEl, {
      'rotateOnStart': true,
      'selectEnabled': false,
      'styles': {polyline: {defaultOpacity: 1, deselectedOpacity: 1}}
    });
    this.scatterGLHasInitialized = false;
    this.videoWidth = canvas.width;
    this.videoHeight = canvas.height;
    this.flip(this.videoWidth, this.videoHeight);
  }

  flip(videoWidth, videoHeight) {
    // Because the image from camera is mirrored, need to flip horizontally.
    this.ctx.translate(videoWidth, 0);
    this.ctx.scale(-1, 1);

    this.scatterGLEl.style =
        `width: ${videoWidth}px; height: ${videoHeight}px;`;
    this.scatterGL.resize();

    this.scatterGLEl.style.display =
        params.STATE.modelConfig.render3D ? 'inline-block' : 'none';
  }

  draw(rendererParams) {
    const [video, poses, isModelChanged] = rendererParams;
    this.drawCtx(video);

    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (poses && poses.length > 0 && !isModelChanged) {
      this.drawResults(poses);
    }
  }

  drawCtx(video) {
    this.ctx.drawImage(video, 0, 0, this.videoWidth, this.videoHeight);
  }

  clearCtx() {
    this.ctx.clearRect(0, 0, this.videoWidth, this.videoHeight);
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param poses A list of poses to render.
   */
  drawResults(poses) {
    for (const pose of poses) {
      this.drawResult(pose);
    }
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param pose A pose with keypoints to render.
   */
  drawResult(pose) {
    if (pose.keypoints != null) {
      this.drawKeypoints(pose.keypoints);
      this.drawSkeleton(pose.keypoints, pose.id);
      this.drawAngles(pose.keypoints, pose.keypoints3D);
    }
    if (pose.keypoints3D != null && params.STATE.modelConfig.render3D) {
      this.drawKeypoints3D(pose.keypoints3D);
    }
  }

  getMiddle(kp1, kp2) {
    // To calculate the base of the neck or the position of the lumbar spine we
    // need to calculate the middle position between two keypoints in 3Dspace.
    const midKP = {
      x: (kp1.x + kp2.x) / 2,
      y: (kp1.y + kp2.y) / 2,
      z: (kp1.z + kp2.z) / 2,
      };
    return midKP;
  }

  /**
   * Draw the keypoints on the video.
   * @param keypoints A list of keypoints.
   */
  drawKeypoints(keypoints) {
    const keypointInd =
        posedetection.util.getKeypointIndexBySide(params.STATE.model);
    this.ctx.fillStyle = 'Red';
    this.ctx.strokeStyle = 'White';
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    for (const i of keypointInd.middle) {
      this.drawKeypoint(keypoints[i]);
    }

    // // Middlepoints
    // const middleShoulders = this.getMiddle(keypoints[11], keypoints[12]);
    // const middleHips = this.getMiddle(keypoints[23], keypoints[24]);
    // const middleKnees = this.getMiddle(keypoints[25], keypoints[26]);

    // this.drawKeypoint(middleShoulders);
    // this.drawKeypoint(middleHips);
    // this.drawKeypoint(middleKnees);

    // // Usage:
    // const points = [
    //   middleShoulders,
    //   middleHips,
    //   middleKnees,
    // ];

    // const means = bestFitLine(points);
    // console.log(means);

    this.ctx.fillStyle = 'Green';
    for (const i of keypointInd.left) {
      this.drawKeypoint(keypoints[i]);
    }

    this.ctx.fillStyle = 'Orange';
    for (const i of keypointInd.right) {
      this.drawKeypoint(keypoints[i]);
    }
  }

  drawKeypoint(keypoint) {
    // If score is null, just show the keypoint.
    const score = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

    if (score >= scoreThreshold) {
      const circle = new Path2D();
      circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
      this.ctx.fill(circle);
      this.ctx.stroke(circle);
    }
  }

  /**
   * Draw the skeleton of a body on the video.
   * @param keypoints A list of keypoints.
   */
  drawSkeleton(keypoints, poseId) {
    // Each poseId is mapped to a color in the color palette.
    const color = params.STATE.modelConfig.enableTracking && poseId != null ?
        COLOR_PALETTE[poseId % 20] :
        'White';
    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    posedetection.util.getAdjacentPairs(params.STATE.model).forEach(([
                                                                      i, j
                                                                    ]) => {
      const kp1 = keypoints[i];
      const kp2 = keypoints[j];

      // If score is null, just show the keypoint.
      const score1 = kp1.score != null ? kp1.score : 1;
      const score2 = kp2.score != null ? kp2.score : 1;
      const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

      if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
        this.ctx.beginPath();
        this.ctx.moveTo(kp1.x, kp1.y);
        this.ctx.lineTo(kp2.x, kp2.y);
        this.ctx.stroke();
      }
    });
  }

  drawKeypoints3D(keypoints) {
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
    const pointsData =
        keypoints.map((keypoint) => ([-keypoint.x, -keypoint.y, -keypoint.z]));

    const dataset =
        new scatter.ScatterGL.Dataset([...pointsData, ...ANCHOR_POINTS]);

    const keypointInd =
        posedetection.util.getKeypointIndexBySide(params.STATE.model);
    this.scatterGL.setPointColorer((i) => {
      if (keypoints[i] == null || keypoints[i].score < scoreThreshold) {
        // hide anchor points and low-confident points.
        return '#ffffff';
      }
      if (i === 0) {
        return '#ff0000'; /* Red */
      }
      if (keypointInd.left.indexOf(i) > -1) {
        return '#00ff00'; /* Green */
      }
      if (keypointInd.right.indexOf(i) > -1) {
        return '#ffa500'; /* Orange */
      }
    });

    if (!this.scatterGLHasInitialized) {
      this.scatterGL.render(dataset);
    } else {
      this.scatterGL.updateDataset(dataset);
    }
    const connections = posedetection.util.getAdjacentPairs(params.STATE.model);
    const sequences = connections.map((pair) => ({indices: pair}));
    this.scatterGL.setSequences(sequences);
    this.scatterGLHasInitialized = true;
  }
  
  drawAngles(keypoints, keypoints3D) {
    // Middlepoints
    const middleShoulders = this.getMiddle(keypoints3D[11], keypoints3D[12]);
    const middleHips = this.getMiddle(keypoints3D[23], keypoints3D[24]);
    const middleKnees = this.getMiddle(keypoints3D[25], keypoints3D[26]);

    const middleHips2D = this.getMiddle(keypoints[23], keypoints[24]);

    this.drawKeypoint(middleHips2D);
    // this.drawKeypoint(middleHips);
    // this.drawKeypoint(middleKnees);

    // Usage:
    const points = [
      middleShoulders,
      middleHips,
      middleKnees,
    ];

    const means = bestFitLine(points);
    console.log(means);
    // console.log(middleShoulders2D.x, middleShoulders2D.y)

    this.ctx.beginPath();
        this.ctx.moveTo(middleHips2D.x + 1000 * means[0], middleHips2D.y - 1000 );
        this.ctx.lineTo(middleHips2D.x - 1000 * means[0], middleHips2D.y + 1000 );
        this.ctx.stroke();

    const angleThreshold = params.STATE.modelConfig.angleThreshold || 0;
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
  
    for (const angleName in ANGLES_TO_KEYPOINTS) {
      const [i, j, k] = ANGLES_TO_KEYPOINTS[angleName];
      const kp1 = keypoints[i];
      const kp2 = keypoints[j];
      const kp3 = keypoints[k];
      const kp1_3D = keypoints3D ? keypoints3D[i] : null;
      const kp2_3D = keypoints3D ? keypoints3D[j] : null;
      const kp3_3D = keypoints3D ? keypoints3D[k] : null;
  
      const score1 = kp1.score != null ? kp1.score : 1;
      const score2 = kp2.score != null ? kp2.score : 1;
      const score3 = kp3.score != null ? kp3.score : 1;
  
      if (score1 >= scoreThreshold && score2 >= scoreThreshold && score3 >= scoreThreshold) {
        if (keypoints3D) {
          const angle3D = this.calculateAngle3D(kp1_3D, kp2_3D, kp3_3D);
          const angle = this.calculateAngle(kp1, kp2, kp3);
          this.drawAngleLabel(angleName, angle3D, kp1, kp2, kp3);
          this.drawAngleArc(kp1, kp2, kp3, angle);
        } else {
          const angle = this.calculateAngle(kp1, kp2, kp3);
          this.drawAngleLabel(angleName, angle, kp1, kp2, kp3);
          this.drawAngleArc(kp1, kp2, kp3, angle);
        }
      }
    }
  }
  
  
  calculateAngle(kp1, kp2, kp3) {
    const bone1 = {
      x: kp1.x - kp2.x,
      y: kp1.y - kp2.y
    };
  
    const bone2 = {
      x: kp3.x - kp2.x,
      y: kp3.y - kp2.y
    };
  
    const dotProduct = bone1.x * bone2.x + bone1.y * bone2.y;
    const magnitude1 = Math.sqrt(bone1.x * bone1.x + bone1.y * bone1.y);
    const magnitude2 = Math.sqrt(bone2.x * bone2.x + bone2.y * bone2.y);
    
    const angle = Math.acos(dotProduct / (magnitude1 * magnitude2)) * (180 / Math.PI);
    return angle.toFixed(1);
  }
  

  calculateAngle3D(kp1, kp2, kp3) {
    const bone1 = {
      x: kp1.x - kp2.x,
      y: kp1.y - kp2.y,
      z: kp1.z - kp2.z,
    };
  
    const bone2 = {
      x: kp3.x - kp2.x,
      y: kp3.y - kp2.y,
      z: kp3.z - kp2.z,
    };

    const dotProduct = bone1.x * bone2.x + bone1.y * bone2.y + bone1.z * bone2.z;
    const magnitude1 = Math.sqrt(bone1.x * bone1.x + bone1.y * bone1.y + bone1.z * bone1.z);
    const magnitude2 = Math.sqrt(bone2.x * bone2.x + bone2.y * bone2.y + bone2.z * bone2.z);
    
    const angle = Math.acos(dotProduct / (magnitude1 * magnitude2)) * (180 / Math.PI);
    return angle.toFixed(1);
  }
  
  drawAngleLabel(angleName, angle, kp1, kp2, kp3) {
    const radius = 20; // Define the radius of the arc
    const radialOffset = 30; // Define radial offset
  
    const bone1 = {
      x: kp1.x - kp2.x,
      y: kp1.y - kp2.y
    };
  
    const bone2 = {
      x: kp3.x - kp2.x,
      y: kp3.y - kp2.y
    };
  
    // Calculate the cross product between the two bone vectors.
    const crossProduct = bone1.x * bone2.y - bone1.y * bone2.x;
  
    let startAngle = Math.atan2(kp1.y - kp2.y, kp1.x - kp2.x);
  
    // Calculate the midpoint angle
    let midAngle;
    if (crossProduct > 0) {
      midAngle = startAngle + (angle / 2) * (Math.PI / 180);
    } else {
      midAngle = startAngle - (angle / 2) * (Math.PI / 180);
    }
  
    // Calculate the position of the text with the radial offset
    const textX = kp2.x + (radius + radialOffset) * Math.cos(midAngle);
    const textY = kp2.y + (radius + radialOffset) * Math.sin(midAngle);
  
    this.ctx.save(); // Save the current context state
    this.ctx.scale(-1, 1); // Apply the opposite transformation for the text
    this.ctx.font = '14px Arial';
    this.ctx.fillStyle = 'Yellow';
    this.ctx.fillText(`${Math.round(angle / 5) * 5}°`, -textX, textY); // Apply the offset
    this.ctx.restore(); // Restore the context state
  }

  drawAngleArc(kp1, kp2, kp3, angle) {
    const radius = 20; // Define the radius of the arc
    
    const bone1 = {
      x: kp1.x - kp2.x,
      y: kp1.y - kp2.y
    };
  
    const bone2 = {
      x: kp3.x - kp2.x,
      y: kp3.y - kp2.y
    };
  
    // Calculate the cross product between the two bone vectors.
    const crossProduct = bone1.x * bone2.y - bone1.y * bone2.x;
  
    let startAngle = Math.atan2(kp1.y - kp2.y, kp1.x - kp2.x);
    let endAngle;
    
    this.ctx.beginPath();
    this.ctx.strokeStyle = 'Yellow';
    this.ctx.lineWidth = 2;
    if (crossProduct > 0) {
      endAngle = startAngle + angle * (Math.PI / 180);
      this.ctx.arc(kp2.x, kp2.y, radius, startAngle, endAngle, false);
    } else {
      endAngle = startAngle - angle * (Math.PI / 180);
      this.ctx.arc(kp2.x, kp2.y, radius, startAngle, endAngle, true);
    }
    this.ctx.stroke();
  }
  

}
