/* eslint-disable linebreak-style */
import * as tf from '@tensorflow/tfjs-core';
import {showBackendConfigs} from './option_panel';
import {STATE, TUNABLE_FLAG_VALUE_RANGE_MAP} from './params';

export function isiOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
  return isAndroid() || isiOS();
}

/**
 * Reset the target backend.
 *
 * @param backendName The name of the backend to be reset.
 */
async function resetBackend(backendName) {
  const ENGINE = tf.engine();
  if (!(backendName in ENGINE.registryFactory)) {
    if (backendName === 'webgpu') {
      // eslint-disable-next-line max-len
      alert('webgpu backend is not registered. Your browser may not support WebGPU yet. To test this backend, please use a supported browser, e.g. Chrome canary with --enable-unsafe-webgpu flag');
      // eslint-disable-next-line max-len
      STATE.backend = !!STATE.lastTFJSBackend ? STATE.lastTFJSBackend : 'tfjs-webgl';
      showBackendConfigs();
      return;
    } else {
      throw new Error(`${backendName} backend is not registered.`);
    }
  }

  if (backendName in ENGINE.registry) {
    const backendFactory = tf.findBackendFactory(backendName);
    tf.removeBackend(backendName);
    tf.registerBackend(backendName, backendFactory);
  }

  await tf.setBackend(backendName);
  STATE.lastTFJSBackend = `tfjs-${backendName}`;
}

/**
 * Set environment flags.
 *
 * This is a wrapper function of `tf.env().setFlags()` to constrain users to
 * only set tunable flags (the keys of `TUNABLE_FLAG_TYPE_MAP`).
 *
 * ```js
 * const flagConfig = {
 *        WEBGL_PACK: false,
 *      };
 * await setEnvFlags(flagConfig);
 *
 * console.log(tf.env().getBool('WEBGL_PACK')); // false
 * console.log(tf.env().getBool('WEBGL_PACK_BINARY_OPERATIONS')); // false
 * ```
 *
 * @param flagConfig An object to store flag-value pairs.
 */
export async function setBackendAndEnvFlags(flagConfig, backend) {
  if (flagConfig == null) {
    return;
  } else if (typeof flagConfig !== 'object') {
    throw new Error(
        `An object is expected, while a(n) ${typeof flagConfig} is found.`);
  }

  // Check the validation of flags and values.
  // eslint-disable-next-line guard-for-in
  for (const flag in flagConfig) {
    // TODO: check whether flag can be set as flagConfig[flag].
    if (!(flag in TUNABLE_FLAG_VALUE_RANGE_MAP)) {
      throw new Error(`${flag} is not a tunable or valid environment flag.`);
    }
    if (TUNABLE_FLAG_VALUE_RANGE_MAP[flag].indexOf(flagConfig[flag]) === -1) {
      throw new Error(
          `${flag} value is expected to be in the range [${
              TUNABLE_FLAG_VALUE_RANGE_MAP[flag]}], while ${flagConfig[flag]}` +
          ' is found.');
    }
  }

  tf.env().setFlags(flagConfig);

  const [runtime, $backend] = backend.split('-');

  if (runtime === 'tfjs') {
    await resetBackend($backend);
  }
}


export function bestFitLine(points) {
  // await tf.ready();
  // Convert points to a matrix
  const matrix = tf.tensor2d(points.map(({x, y, z}) => [x, y, z]));

  // Compute the mean of each column (dimension)
  const means = tf.mean(matrix, 0);
  // matrix.print();
  // means.print();
  const result = means.arraySync();
  // console.log(result);

  // // Center the matrix by subtracting the means
  // const centeredMatrix = tf.sub(matrix, means);

  // // Compute the covariance matrix
  // // eslint-disable-next-line max-len
  // const transposeMatrix = tf.transpose(centeredMatrix);
  // const selfMulMatrix = tf.matMul(transposeMatrix, centeredMatrix);
  // const covarianceMatrix = tf.div(selfMulMatrix, tf.scalar(points.length - 1));

  // // Compute the eigenvalues and eigenvectors of the covariance matrix
  // const {eigenvalues, eigenvectors} = await tf.linalg.eig(covarianceMatrix);

  // // The best fitting line is along the eigenvector corresponding 
  // // to the largest eigenvalue
  // // eslint-disable-next-line max-len
  // const maxEigenvalueIndex = (await eigenvalues.array()).reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
  // // eslint-disable-next-line max-len
  // const bestLine = eigenvectors.slice([0, maxEigenvalueIndex], [-1, 1]).reshape([-1]);

  // Return the direction of the best fitting line and a point
  // on the line (the mean of the points)
  // eslint-disable-next-line max-len
  // return {direction: await bestLine.array(), pointOnLine: await means.array()};
  return result;
}
